@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --text-color-secondary: theme('colors.gray.500');

  --background: theme('colors.gray.100');
  --surface: theme('colors.white');
  --surface-overlay: theme('colors.gray.200');
  --underlay: theme('colors.gray.200');

  --divider: theme('colors.gray.400');
  --skeleton: theme('colors.gray.300');

  --primary: theme('colors.blue.900');
  --primary-overlay: theme('colors.white');
}

html.dark {
  --text-color-secondary: theme('colors.gray.300');

  --background: theme('colors.gray.800');
  --surface: theme('colors.gray.700');
  --surface-overlay: theme('colors.gray.600');

  --divider: theme('colors.gray.600');
  --skeleton: theme('colors.gray.600');

  --primary: theme('colors.blue.600');
  --primary-overlay: theme('colors.white');
}

.with-overlay {
  @apply relative cursor-pointer;

  &::after {
    content: '';
    border-radius: inherit;

    @apply absolute inset-0 bg-black bg-opacity-025 hidden;
  }

  &:hover::after {
    @apply block;
  }
}
.dark .with-overlay::after {
  @apply bg-opacity-10;
}

html,
body {
  height: 100%;
}

body {
  /* @apply tracking-wide; */

  font-size: 1rem;
  @apply text-gray-800 dark:text-white;

  /* reconsider different font? */
  /* -webkit-font-smoothing: antialiased; */
}

/* Links */

a {
  /* TODO: Make it text-info and not primary? */
  @apply text-primary underline hover:no-underline;
}

p + p {
  @apply mt-3;
}

#root {
  @apply h-full flex flex-col;
}

/* Lottie */

svg.path-stroke-current path {
  @apply stroke-current;
}

/* Switch */

.switch {
  @apply relative inline-block align-middle cursor-pointer select-none bg-transparent;
}

.switch .track {
  @apply w-12 h-6 bg-gray-300 rounded-full;
}

.switch .thumb {
  @apply transition-all duration-300 ease-in-out absolute top-0 left-0 w-6 h-6 bg-white border-2 border-gray-300 rounded-full;
}

.switch input[type="checkbox"]:checked ~ .thumb {
  @apply transform translate-x-full border-blue-900 dark:border-blue-600;
}

.switch input[type="checkbox"]:checked ~ .track {
  @apply transform transition-colors bg-blue-900 dark:bg-blue-600;
}

.switch input[type="checkbox"]:disabled ~ .track {
  @apply bg-gray-500 cursor-not-allowed;
}

.switch input[type="checkbox"]:disabled ~ .thumb {
  @apply bg-gray-100 border-gray-500 cursor-not-allowed;
}

.switch input[type="checkbox"]:focus + .track,
.switch input[type="checkbox"]:active + .track {
  @apply ring-2 ring-offset-2;
}

/* Radio */

.radio {
  @apply cursor-pointer flex items-center;
}

.radio .checkmark {
  @apply w-6 h-6 flex-shrink-0 relative border-2 border-primary rounded-full mx-5;
}

.radio .checkmark::after {
  content: '';

  @apply absolute top-0.5 left-0.5 w-4 h-4 rounded-full bg-primary opacity-0 transition-opacity duration-150;
}

.radio input[type="radio"]:checked ~ .checkmark::after {
  @apply opacity-100;
}

.radio input[type="radio"]:disabled ~ .checkmark {
  @apply border-gray-500;

  &::after {
    @apply bg-gray-500;
  }
}

/* Checkbox */

.checkbox {
  @apply flex items-center;
}

.checkbox .checkmark {
  @apply w-6 h-6 flex-shrink-0 relative border-2 border-primary rounded-lg mx-5;
}

.checkbox .checkmark::after {
  content: '';

  border-width: 0 2px 2px 0;

  @apply absolute top-0.5 left-1.5 w-2 h-3.5 border-primary transform rotate-45 opacity-0 transition-opacity duration-150;
}

.checkbox input[type="checkbox"]:checked ~ .checkmark::after {
  @apply opacity-100;
}

.checkbox input[type="checkbox"]:disabled ~ .checkmark {
  @apply border-gray-500;

  &::after {
    @apply border-gray-500;
  }
}

/* Headings */

h1, h2, h3, h4, h5, h6 {
  @apply font-medium;
}
h1 {
  @apply text-2xl;
}
h2 {
  @apply text-xl;
}
h3 {
  @apply text-lg;
}

/* Cluster */

.cluster {
  --space: 1rem;

  display: flex;
  margin: calc(var(--space) / 2 * -1);
}

.cluster > * {
  margin: calc(var(--space) / 2);
}

/* FormField */

fieldset {
  display: grid;
  gap: 1rem;
}

/* Lists */

ul,
ol {
  @apply pl-6;
}

ul {
  @apply list-disc;
}

ol {
  @apply list-decimal;
}
